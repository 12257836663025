

















import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import NotificationsTable from "@/components/notifications/NotificationsTable.vue"
import {mapGetters,mapActions} from 'vuex'
export default Vue.extend({
  components: {
    Layout,
    NotificationsTable
  },
  created(){
    if(this.notifications.length<1){
      this.fetchNotifications()
    }
  },
  methods:{
    ...mapActions("azNotificationModule",{
      fetchNotifications:"FETCH_NOTIFICATIONS"
    }),
    getSearchValue(val){

      this.searchText=val
    }
  },
  data() {
    return {
      showModal: false,
      title: "Notifications",
      searchText:'',
    };
  },
});
