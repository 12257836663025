












































































import Vue from "vue";
import { mapActions } from "vuex";
import SearchBar from "@/components/search-bar.vue";
export default Vue.extend({
  props: {
    searchText: {
      type: String,
    },
  },
  components: {
    "search-bar": SearchBar
  },
  data(){
    return {
      currentPage: 1,
      perPage: 10
    }
  },

  computed:{
    loc_notifications(){
      let temp_notification= this.notifications.map(item=>{
        return {
          ...item,
          languages: [
            {
              lang: 'English',
              translated: true,
              translation_changed: false,
            },
            {
              lang: 'Slovakia',
              translated: true,
              translation_changed: false,
            },
          ],
        }
      })
      if(this.searchText!=''){
        return temp_notification.filter(item=>item.clientZoneNotificationType.toString().includes(this.searchText.toLowerCase()))
      }
      else{
        return temp_notification
      }
    },

    fields(){
      return [
        {
          key: this.$i18n.locale == "en" ? "clientZoneNotificationNameEn" : "clientZoneNotificationNameSk",
          label: this.$i18n.t('Notification Title'),
        },
        {
          key: "clientZoneNotificationImportance",
          label: this.$i18n.t('Level of Importance'),
        },
        {
          key: 'languages',
          label: 'Languages'
        },
        {
          key: "notificationIntervalInDays",
          label: this.$i18n.t('Repetition'),
        },
      ]
    },

    data() {
      return {
        currentPage: 1,
        perPage: 5,
      };
    },
  },
  methods: {
    // getNotificationTitle(item){
    //   if((item.clientZoneNotificationType==1 || item.clientZoneNotificationType==2) && item.clientZoneNotificationImportance==1){
    //     return "Missing company logo"
    //   }
    //   else if(item.clientZoneNotificationType==3 && item.clientZoneNotificationImportance==1){
    //     return "Credit card expired"
    //   }
    //   else if((item.clientZoneNotificationType==4 || item.clientZoneNotificationType==5) && item.clientZoneNotificationImportance==1){
    //     return "Unprocessed payment warning"
    //   }
    //   else if(item.clientZoneNotificationType==6 && item.clientZoneNotificationImportance==1){
    //     return "Deactivated venue subscription due to unprocessed payment "
    //   }
    //   else if(item.clientZoneNotificationType==7 && item.clientZoneNotificationImportance==1){
    //     return "Missing critical venue details"
    //   }
    //   else if(item.clientZoneNotificationType==8 && item.clientZoneNotificationImportance==2){
    //     return "Missing venue details "
    //   }
    //   else if(item.clientZoneNotificationType==9 && item.clientZoneNotificationImportance==2){
    //     return "End of audio campaign "
    //   }
    //   else if(item.clientZoneNotificationType==9 && item.clientZoneNotificationImportance==2){
    //     return "End of audio campaign"
    //   }
    //   else if(item.clientZoneNotificationType==10 && item.clientZoneNotificationImportance==2){
    //     return "End of custom survey"
    //   }
    //   else if(item.clientZoneNotificationType==11 && item.clientZoneNotificationImportance==3){
    //     return "Messages to customers"
    //   }
    //   else if(item.clientZoneNotificationType==12 && item.clientZoneNotificationImportance==3){
    //     return "Customized venue survey"
    //   }
    //   else {
    //     return "NA"
    //   }
      // return  this.notification_titles[item.clientZoneNotificationType]
    // }
  },
  ...mapActions("azNotificationModule", {
    setNotifications: "FETCH_NOTIFICATIONS"
  }),
});
